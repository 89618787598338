<script setup>

import { onMounted, ref, watch } from "vue";
import useClients from '@/hooks/useClients';
import useWarehouses from '@/hooks/useWarehouses';
import useDamageReports from '@/hooks/useDamageReports';
import useUsers from '@/hooks/useUsers';
import useProducts from '@/hooks/useProducts';
import useLps from '@/hooks/useLps';
import debounce from "lodash/debounce";
import { useToast } from "vue-toastification";

const { clients, fetchClients } = useClients();
const { warehouses, fetchWarehouses } = useWarehouses();
const { damageTypes, fetchDamageTypes, addDamageReport, fetchAvailableBins } = useDamageReports();
const { users, fetchUsers } = useUsers();
const { products, fetchProducts } = useProducts();
const { lps, fetchLps } = useLps();

const items = ref([]);
const newItem = ref({});
const availableBins = ref([]);
const toast = useToast();

const damageReport = ref({
    client_id: null,
    warehouse_id: null,
    product_id: null,
    damage_bin_id: null,
    damaged_by: null,
    damage_type: null,
    note: null,
    po_number: null,
    lp_id: null,
    qty: null
});

const urlParams = {
    filter: {
        name: {
            type: "contains",
            filter: "",
        },
    },
    sort: [
        {
            sort: "asc",
            colId: "name",
        }
    ],
    limit: 100
};

watch(() => damageReport.value.client_id, (newVal, oldVal) => {
    if (newVal) {
        const urlParams = {
            filter: {
                client_id: {
                    type: "equals",
                    filter: newVal,
                },
            },
        };
        fetchProducts(urlParams);
    }
});

watch(() => newItem.value.product, (newVal, oldVal) => {
    if (newVal) {
        getLps("");
    }
});

watch(() => newItem.value.lp, async (newVal, oldVal) => {
    if (newVal) {
        availableBins.value = await fetchAvailableBins(newItem.value.lp.id);
    }
});

const getLps = async (val) => {

    if (!newItem.value.product.id || !damageReport.value.warehouse_id) return;

    const urlParams = {
        filter: {
            product_id: {
                type: "equals",
                filter: newItem.value.product.id
            },
            number: {
                type: "contains",
                filter: val
            }
        }
    };
    fetchLps(urlParams);
}

const debouncedFetchClients = debounce(async (query) => {
    urlParams.filter.name.filter = query;
    await fetchClients(urlParams);
}, 300);

const debouncedFetchUsers = debounce(async (query) => {
    urlParams.filter.name.filter = query;
    await fetchUsers(urlParams);
}, 300);

const debouncedFetchLps = debounce(async (query) => {
    await getLps(query);
}, 300);

const resetProducts = () => {
    newItem.value.product = null;
    resetLps();
    resetBins();
}
const resetLps = () => {
    newItem.value.lp = null;
    resetBins();
}

const resetBins = () => {
    newItem.value.damage_bin = null;
    availableBins.value = [];
}

onMounted(async () => {
    await fetchClients();
    await fetchWarehouses();
    await fetchDamageTypes();
    await fetchUsers();
});

const getDamageTypes = () => {
    return Object.entries(damageTypes.value).map(([key, value]) => {
        return { title: value.label, value: key };
    });
};

const removeItem = (idx) => {
    items.value.splice(idx, 1);
}

const addItems = (item) => {
    if (!item.product) {
        toast.error("Please select a product.");
        return;
    }
    if (!item.lp) {
        toast.error("Please select a LP/Pallet.");
        return;
    }
    items.value.push(Object.assign({}, item));
}

const createReport = () => {
    if (items.value.length === 0) {
        toast.error("Please add a product.");
        return;
    }
    damageReport.value.product_id = items.value[0]?.product?.id;
    damageReport.value.lp_id = items.value[0]?.lp?.id;
    if (items.value[0]?.damage_bin?.id) {
        damageReport.value.damage_bin_id = items.value[0]?.damage_bin.id;
    }
    addDamageReport(damageReport.value);
}

</script>

<template>
    <div class="mb-10 mt-5">
        <v-row class="justify-center">
            <v-col cols="11">
                <v-card>
                    <v-card-title class="bg-surface-light">Select Client</v-card-title>
                    <v-card-text class="mt-10 mb-10 d-flex">
                        <v-autocomplete density="compact" variant="outlined"
                            v-model="damageReport.client_id" hide-details="auto" :items="clients" item-title="name" @update:modelValue="resetProducts"
                            @update:search="(query) => debouncedFetchClients(query)" item-value="id"
                            class="mr-15">
                            <template v-slot:label>
                                Select Client <span class="text-red">*</span>
                            </template>
                        </v-autocomplete>
                        <v-select density="compact" variant="outlined"
                            v-model="damageReport.warehouse_id" hide-details="auto" :items="warehouses?.data"
                            item-title="name" item-value="id">
                            <template v-slot:label>
                                Select Warehouse <span class="text-red">*</span>
                            </template>
                        </v-select>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <div>
        <v-row class="justify-center">
            <v-col cols="11">
                <v-card>
                    <v-card-title class="bg-surface-light">Incident Description</v-card-title>
                    <v-card-text class="mt-10 mr-15">
                        <v-select density="compact" variant="outlined"
                            :items="getDamageTypes()" hide-details="auto" class="mr-15 w-50"
                            v-model="damageReport.damage_type">
                            <template v-slot:label>
                                Damage Type <span class="text-red">*</span>
                            </template>
                        </v-select>
                    </v-card-text>
                    <v-card-text class="mt-10 d-flex">
                        <v-text-field label="PO Number" density="compact" variant="outlined"
                            v-model="damageReport.po_number" hide-details="auto" class="mr-15">
                        </v-text-field>
                        <v-autocomplete label="Select Damaged By" density="compact" variant="outlined"
                            hide-details="auto" :items="users?.data" item-title="name" item-value="id"
                            @update:search="(query) => debouncedFetchUsers(query)"
                            v-model="damageReport.damaged_by"></v-autocomplete>
                    </v-card-text>
                    <v-card-text class="mt-10 d-flex">
                        <v-textarea label="Description" density="compact" variant="outlined"
                            v-model="damageReport.note" hide-details="auto">
                            <template v-slot:label>
                                Description <span class="text-red">*</span>
                            </template>
                        </v-textarea>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <div>
        <v-row class="justify-center">
            <v-col cols="11" v-if="items && items.length > 0">
                <v-card>
                    <v-card-title class="bg-surface-light">Selected Products</v-card-title>
                    <v-card-text class="mt-10">
                        <v-table class="table">
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>LP / Pallet</th>
                                    <th>Quantity</th>
                                    <th>Destination Bin</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, idx) in items">
                                    <td>
                                        {{ item?.product?.name }}<br>
                                        <small>{{ item?.product?.sku }}</small>
                                    </td>
                                    <td>
                                        <strong>LPN: </strong>
                                        {{ item?.lp?.number }}
                                        <br>
                                        <strong>LOT/EXP:</strong>
                                        {{ item?.lp?.lot || '-' }} / {{ item.lp?.expiry || '-' }}
                                    </td>
                                    <td>{{ item?.lp?.pallet_qty }}</td>
                                    <td>{{ item?.damage_bin?.name }}</td>
                                    <td>
                                        <v-btn color="red" size="small" @click="removeItem(idx)"
                                            type="button">Delete</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="11" v-else>
                <v-card>
                    <v-card-title class="bg-surface-light">Add Product(s)</v-card-title>
                    <v-card-text class="mt-10 d-flex">
                        <v-select density="compact" variant="outlined" hide-details="auto"
                            v-model="newItem.product" :items="products" item-title="name" @update:modelValue="resetLps"
                            class="mr-15" return-object>
                            <template v-slot:label>
                                Select Product <span class="text-red">*</span>
                            </template></v-select>
                        <v-autocomplete density="compact" variant="outlined" hide-details="auto"
                            v-model="newItem.lp" @update:search="(query) => debouncedFetchLps(query)" class="mr-15"
                            :items="lps?.data" item-title="number" @update:modelValue="resetBins" return-object>
                            <template v-slot:label>
                                LP/Pallet <span class="text-red">*</span>
                            </template>
                        </v-autocomplete>
                        <v-select clearable label="Destination Bin" density="compact" variant="outlined"
                            :items="availableBins.damage_bins" item-title="name" v-model="newItem.damage_bin" hide-details="auto"
                            return-object></v-select>
                        <v-btn color="primary" density="compact" size="large" class="ml-4"
                            @click="addItems(newItem)">ADD</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <div class="d-flex mt-8 justify-center w-100 gap-2">
        <v-btn color="primary" @click="createReport">Create Report</v-btn>
    </div>
</template>